import React from "react";
import { Link } from "gatsby";
import { Layout } from "../components";

const ContactThankYou = ({ pageContext: { slug } }) => {
  return (
    <Layout
      seo={{
        title: "Kariera potwierdzenie",
        href: slug,
        lang: "pl",
        noIndex: true,
      }}
    >
      <div className="page_thanks">
        <img
          className="img-fluid"
          src={require("../assets/img/brief_thank_you.png")}
          alt=""
        />
        <p className="desc">
          Dziękujemy za wiadomość, skontaktujemy się najszybciej jak to możliwe.{" "}
          <br />
          Do usłyszenia!
        </p>
        <p>
          <Link to="/" className="btn_custom">
            Strona główna
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default ContactThankYou;
